import { DateTime, Interval } from 'luxon';
import TempRedirPlan from './TempRedirPlan';
import Axios from 'axios';
 
export default{

    /**
     * Ajustando para pegar em dias o periodo da assinatura recorrente.
     * OBS: Teve que criar um Helper para ajustar o periodo, pois no office não é salvo como dias, e sim
     * como nomes para os periodos.
     *
     *
     * @param Periodo
     * @returns {number}
     */
    periodoRecorrente(Periodo){
        if(Periodo == 'MENSAL') return 1;
        if(Periodo == 'ANUAL')  return 12;
    },

    /**
     * 
     * Retorna as URLS dos aplicativos e o env conforme o ambiente em que esta
     * 
     * @returns {urlCheckout: '', urlOffice: '', env: '}
     */
    getEnvs(){

        //Local
        if(['localhost', 'checkout.builderall.local'].includes(location.hostname)){
            return {
                urlCheckoutFront: 'checkout.builderall.local',
                urlCheckout: 'http://api-payment.builderall.local',
                urlOffice: 'http://office.builderall.local',
                urlOfficeAdmin: 'http://www.omb100.local',
                urlTranslation: 'http://translation.builderall.local',
                urlLogs: 'http://logger.builderall.local',
                urlTracking: 'https://ot.builderall.io',
                urlWordpress: 'https://wordpress.builderall.io',
                urlCheetah: 'https://cheetah-api.builderall.info',
                webhookDefaultFee: 'http://www.omb100.local/us/notificacao-pagamento-franquia/builderall_gateway',
                webhookDefaultPlan: 'http://www.omb100.local/us/notificacao-pagamento-fatura-franquia/builderall_gateway',
                domainRegistry: 'https://domain-registry.kub.app.builderall.io',
                uiBendaKeys: {
                    publicKey: 'mEPYYz1q001o16nA3wRlu3CXUDZQ0v5h'
                },
                env: 'dev'
            }
        }
    
        //Test
        if(['checkout.builderall.io', 'checkoutfront.kub.app.builderall.io'].includes(location.hostname)){
            return {
                urlCheckoutFront: 'checkout.builderall.io',
                urlCheckout: 'https://api-payment.builderall.io',
                urlOffice: 'https://office.kub.app.builderall.io',
                urlOfficeAdmin: 'https://www.admin.kub.app.builderall.io',
                urlTranslation: 'https://translation.builderall.com',
                urlLogs: 'https://logger.builderall.com',
                urlTracking: 'https://ot.kub.app.builderall.io',
                urlMicroserviceDebitedBuilderall: 'https://microservice.builderall.io',
                urlWordpress: 'https://wordpress.builderall.io',
                urlCheetah: 'https://cheetah-api.builderall.info',
                webhookDefaultFee: 'http://www.omb1000.com/us/notificacao-pagamento-franquia/builderall_gateway',
                webhookDefaultPlan: 'http://www.omb1000.com/us/notificacao-pagamento-fatura-franquia/builderall_gateway',
                domainRegistry: 'https://domain-registry.kub.app.builderall.io',
                uiBendaKeys: {
                    publicKey: 'mEPYYz1q001o16nA3wRlu3CXUDZQ0v5h'
                },
                env: 'test'
            }
        }

        //Homolog
        if(['checkout.builderall.info', 'checkoutfront.kub.app.builderall.info'].includes(location.hostname)){
            return {
                urlCheckoutFront: 'checkout.builderall.info',
                urlCheckout: 'https://api-payment.builderall.com',
                urlOffice: 'https://office.kub.app.builderall.info',
                urlOfficeAdmin: 'https://www.admin.kub.app.builderall.info',
                urlTranslation: 'https://translation.builderall.com',
                urlLogs: 'https://logger.builderall.com',
                urlTracking: 'https://ot.kub.app.builderall.info',
                urlMicroserviceDebitedBuilderall: 'https://microservice.builderall.com',
                urlWordpress: 'https://wordpress.builderall.com',
                urlCheetah: 'https://cheetah-api.builderall.info',
                webhookDefaultFee: 'http://www.omb100.com/us/notificacao-pagamento-franquia/builderall_gateway',
                webhookDefaultPlan: 'http://www.omb100.com/us/notificacao-pagamento-fatura-franquia/builderall_gateway',
                domainRegistry: 'https://domain-registry.kub.app.builderall.info',
                uiBendaKeys: {
                    publicKey: '1Id8QTPw8ke5T45yWzNMSoYN6y1VUJyu'
                },
                env: 'info'
            }
        }

        //Checkout V3
        if(['checkoutv3.builderall.io'].includes(location.hostname)){
            return {
                urlCheckoutFront: 'checkoutv3.builderall.io',
                urlCheckout: 'https://api-payment.builderall.io',
                urlOffice: 'https://office.kub.app.builderall.io',
                urlOfficeAdmin: 'https://www.admin.kub.app.builderall.io',
                urlTranslation: 'https://translation.builderall.io',
                urlLogs: 'https://logger.builderall.com',
                urlTracking: 'https://ot.builderall.io',
                urlMicroserviceDebitedBuilderall: 'https://microservice.builderall.io',
                urlWordpress: 'https://wordpress.builderall.io',
                urlCheetah: 'https://cheetah-api.builderall.info',
                webhookDefaultFee: 'http://www.omb1000.com/us/notificacao-pagamento-franquia/builderall_gateway',
                webhookDefaultPlan: 'http://www.omb1000.com/us/notificacao-pagamento-fatura-franquia/builderall_gateway',
                domainRegistry: 'https://domain-registry.kub.app.builderall.io',
                uiBendaKeys: {
                    publicKey: 'mEPYYz1q001o16nA3wRlu3CXUDZQ0v5h'
                },
                env: 'test'
            }
        }

        //Produção
        if(['checkout.builderall.com', 'checkoutv3.builderall.com', 'checkoutfront.kub.app.builderall.com'].includes(location.hostname)){
            return {
                urlCheckoutFront: 'checkout.builderall.com',
                urlCheckout: 'https://api-payment.builderall.com',
                urlOffice: 'https://office.builderall.com',
                urlOfficeAdmin: 'https://www.omb100.com',
                urlTranslation: 'https://translation.builderall.com',
                urlLogs: 'https://logger.builderall.com',
                urlTracking: 'https://ot.builderall.com',
                urlMicroserviceDebitedBuilderall: 'https://microservice.builderall.com',
                urlWordpress: 'https://wordpress.builderall.com',
                urlCheetah: 'https://cheetah-api.builderall.com',
                webhookDefaultFee: 'http://www.omb100.com/us/notificacao-pagamento-franquia/builderall_gateway',
                webhookDefaultPlan: 'http://www.omb100.com/us/notificacao-pagamento-fatura-franquia/builderall_gateway',
                domainRegistry: 'https://domain-registry.builderall.com',
                uiBendaKeys: {
                    publicKey: '1Id8QTPw8ke5T45yWzNMSoYN6y1VUJyu'
                },
                env: 'prod'
            }
        }
    },

    /**
     * Converte a palavra para ser chave para tradução. 
     * @param Palavra
     * @returns {string}
     */
    transformePalavraTraducao(Palavra){
        return Palavra.toLowerCase()
            .replace(/<(.*?)>/g, '') //Remove tags HTML
            .replace(/({[\d]})(\W)/g, '') //Remove interpolações
            .replace(/[^a-z0-9]/g, ' ') //Apenas letras/numeros
            .replace(/\s+/g, ' ') //Remove espaços duplos
            .replace(/\s/g, '_'); //Transforma espaços em _
    },

    /**
     * Retorna um array com todas as siglas disponveis do office
     * 
     * @returns {array}
     */
    siglasOffice(){
        return ['us','br','ir','de','gb','nl','ee','au','es','pt','dk','lt','bo','pe','py','fr','pl','it','mx','no','ar','cr','co','ec','ve','cl','gt','il','ru','si','ro','vi','hu','cn','hi','ko','bn','th','el','fa','ja'];    
    },
    
    /**
     * Converte a sigla do pais do office, para sigla correta da API de tradução do checkout da builderall.
     * 
     * @param Sigla
     */
    convertSigla(Sigla){
        if(Sigla == 'br') return 'pt';
        if(Sigla == 'us') return 'en';
        if(Sigla == 'il') return 'iw';
        if(Sigla == 'he') return 'iw';
        if(Sigla == 'ro') return 'en';
        
        return Sigla;
    },

    /**
     * Converte a sigla do checkout(browser), para siglas do office.
     *
     * @param Sigla
     */
    convertCountryCodeToOffice(countryCode){
        if(countryCode == 'pt') return 'br';

        return countryCode;
    },
    
    langDefault: 'en',

    /**
     * 7 é o valor do builderall gateway
     */
    builderallGatewayId: 7,

    /**
     * Versao de tradução do checkout
     */
    translateVersion: 'builderall_checkout_v3',

    /**
     * List Accounts Builderall
     */
    builderallAccounts: {
        internetMarketingLLC_10: '10',
        internetMarketingLLC_30877: '30877',
        internetMarketingLLC_209282: '209282',
        impact_2494737: '2494737'
    },

    /**
     * Pega a sigla do browser do usuario, por padrão a sigla é en (English)
     * 
     * @returns {string}
     */
    getLangBrowser(){
        let lang = 'en';
        
        if(navigator.language){
            lang = navigator.language.substring(0, 2);    
        }
        
        return lang;
    },

    /**
     * Retorna a URL do office, conforme a linguagem do browser
     * 
     * @param Endpoint
     * @returns {string}
     */
    getUrlOffice(Endpoint){
        let langBrowser = this.getLangBrowser();
        
        let langOffice = this.siglasOffice().find(item => {
            let convertSigla = this.convertSigla(item);
            if(convertSigla == langBrowser){
                return item;
            }
        });
        
        //Caso o linguagem for indefinida, deixa o default com US
        if(langOffice === undefined) langOffice = 'us';
        if(langBrowser == 'he') langOffice = 'il';
        
        return `${this.getEnvs().urlOffice}/${langOffice}/${Endpoint}`;
    },

    /**
     * Retorna os tipos de moedas disponiveis para pagamentos no checkout
     * 
     * @returns {string[]}
     */
    getCurrencyAcceppt(){
        return [
            'AED', 'ARS', 'AUD', 'BRL', 'CAD', 'CHF', 'CLP', 'COP', 'CZK', 'DKK', 'EGP', 'EUR', 'GBP', 'HKD', 'HUF', 'INR', 'IDR', 'ILS', 'JPY', 'KES', 'KRW', 'MYR', 'MXN', 'MAD', 'NZD', 'NGN', 'NOK', 'PKR', 'PEN', 'PHP', 'PLN', 'SAR', 'SEK', 'SGD', 'THB', 'TRY', 'TWD', 'USD', 'VND', 'ZAR'
        ];
    },

    /**
     * Total de tentativas de pagamento com possivel fraude.
     * 
     */
    possibleFraudAttempts: 3,

    /**
     * Lista de codigos dos paises da europa que precisam de VAT
     * 
     * 
     * @TODO Retornar do banco de dados os codigos dos paises, e adicionar no store
     */
    listCountryEurope: [
        "SK","SI","SE","RO","PT","PL","NL","MT","LV","LU","LT","IT","IE","HU","HR","GR","GB","FR","FI","ES","EE","DK","DE","CZ","CY","BG","BE","AT"
    ],

    countryDutch: 'NL',
    
    /**
     * Calcular VAT
     * 
     * @param Amount Valor do produto
     * @returns {number} Retorna o valor da taxa(VAT) aplicada
     */
    calculateVat(Amount, Percentage){
        return ((Amount * Percentage) / 100).toFixed(2); 
    },

    /**
     * Formata o valor para exibição total de desconto
     * 
     * @param PlanOrFeeAmount
     * @param ValueDiscount
     * @param Type
     * @returns {number}
     */
    calcDiscount(PlanOrFeeAmount, ValueDiscount, Type)
    {
        switch(Type){
            case 'FIXED_VALUE':
                return ValueDiscount;
            break;

            case 'PERCENTAGE_OF_DISCOUNT':
                return (PlanOrFeeAmount - ((PlanOrFeeAmount * ValueDiscount) / 100)).toFixed(2);
            break;
        }
    },

    formatValueDiscount(PlanOrFeeAmount, ValueDiscount, Type)
    {
        switch(Type){
            case 'FIXED_VALUE':
                return ValueDiscount;
            break;

            case 'PERCENTAGE_OF_DISCOUNT':
                return ValueDiscount;
            break;
        }
    },

    /**
     * Adds or updates a URL parameter.
     *
     * @param {string} url  the URL to modify
     * @param {string} param  the name of the parameter
     * @param {string} paramVal  the new value for the parameter
     * @return {string}  the updated URL
     */
    setUrlParam(url, param, paramVal, setHowLast) {
        var urlSplited = url.split('#'),
            parts = urlSplited[0].split('?'),
            baseUrl = parts[0],
            newParameters = [];
        // cast boolean
        setHowLast = !!setHowLast;
        if (!setHowLast) {
            if (paramVal !== '' && paramVal !== null && typeof paramVal !== 'undefined') {
                var paramValue = param == 'redir' ? paramVal : encodeURI(paramVal);
                newParameters.push(param + '=' + paramValue);
            }
        }
        for (var i = 1, len = parts.length; i < len; i++) {
            var oldQueryString = parts[i];
            if (!!oldQueryString) {
                var oldParameters = oldQueryString.split('&');
                for (var i = 0; i < oldParameters.length; i++) {
                    if (oldParameters[i].split('=')[0] != param) {
                        newParameters.push(oldParameters[i]);
                    }
                }
            }
        }
        if (setHowLast) {
            if (paramVal !== '' && paramVal !== null && typeof paramVal !== 'undefined') {
                var paramValue = param == 'redir' ? paramVal : encodeURI(paramVal);
                newParameters.push(param + '=' + paramValue);
            }
        }
        if (newParameters.length > 0) {
            baseUrl += '?' + newParameters.join('&');
        }
        if (urlSplited.length == 2) {
            baseUrl += '#' + urlSplited[1];
        }
        return baseUrl;
    },
    serialize(obj, prefix){
        var str = [], p;
        for (p in obj) {
            if (obj.hasOwnProperty(p)) {
                var k = prefix ? prefix + '[' + p + ']' : p,
                    v = obj[p];
                str.push(
                    (v !== null && typeof v === 'object')
                        ? this.serialize(v, k)
                        : encodeURIComponent(k) + '=' + encodeURIComponent(v)
                );
            }
        }
        return str.join('&');
    },
    getAidOffice(){
        var hash         = location.hash,
            hasHash      = !!hash.length,
            _idPaiOffice = null,
            match = null;

        if (hasHash) {
            if (match = hash.match(/aid=([0-9]+)/)) {
                _idPaiOffice = match[1];
            }
        }

        return _idPaiOffice;
    },
    getParamHash(name){
        let urlWithHash = new URLSearchParams(decodeURIComponent(location.hash.replace('#', '')));

        return urlWithHash.get(name) ? urlWithHash.get(name) : false;
    },
    setBuilderallPixel(aid, lang, subdomain){
        if(!!aid) {
            let params = {
                query: 'p1=rd&p2=https://' + location.hostname,
                id_user_office: aid
            };
            (function (image, params, hostname, referrer, urlCheckout, urlTracking) {
                let url    = '//{domain}/{lang}/franchise/share/{id}?noredirect&from={hostname}&{query}&{page_or_sd}',
                    query  = params.query,
                    domain = `${urlTracking.substr(8)}/api/v1`; //Utiliza o substr para remover o https

                if(location.search.length) {
                    query += location.search.replace('?', '&');
                }

                let regexCheckout = new RegExp(`^${urlCheckout}$`);
                if (regexCheckout.test(hostname) && referrer) {
                    let rgx = /^https?\:\/\/?(([\w+\.\-]+))/;
                    hostname = referrer.match(rgx)[1];
                }

                if (params.id_user_office) {
                    image.src = url.replace('{id}', params.id_user_office)
                        .replace('{domain}', domain)
                        .replace('{query}', query)
                        .replace('{hostname}', hostname)
                        .replace('{lang}', lang)
                        .replace('{page_or_sd}', `${subdomain == null ? `page=${window.location.hostname}${window.location.pathname}` : `sd=${subdomain}`}`);
                }
            })(new Image(), params, location.hostname, document.referrer, this.getEnvs().urlCheckoutFront, this.getEnvs().urlTracking);
        }
    },
    setPixelBuilderallFinishPayment(UserId, UrlAfterPayment, ClickedBtnGoUrl = false){
        
        if(ClickedBtnGoUrl){
            (function (image, Env) {
                var url    = '//{domain}/us/funnel-continue-wipe',
                    domain = Env == 'prod' ? 'office.builderall.com' : 'office.kub.app.builderall.io';
                image.src = url.replace('{domain}', domain);
            })(new Image(), this.getEnvs().env);
            
            return;
        }
        
        (function (image, userId, urlAfterPayment, Env) {
            var url    = '//{domain}/us/funnel-continue/{id}?continue-url={url}',
                domain = Env == 'prod' ? 'office.builderall.com' : 'office.kub.app.builderall.io';
            image.src = url.replace('{id}', userId)
                .replace('{domain}', domain)
                .replace('{url}', urlAfterPayment);
        })(new Image(), UserId, UrlAfterPayment, this.getEnvs().env);  
    },
    isMobile(){
        if( navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
        ){
            return true;
        }
        else {
            return false;
        }
    },
    /**
     * 
     * @param StartDate
     * @param EndDate
     * @returns {{days: number, months: number, years: number}}
     */
    intervalBetweenDates(StartDate, EndDate){
        let dateStart = DateTime.fromISO(StartDate);
        let dateNow = EndDate == undefined ? DateTime.local() : DateTime.fromISO(EndDate);
        let interval = Interval.fromDateTimes(dateStart, dateNow);
        
        return {
            days: interval.count('days'),
            months: interval.count('months'),
            years: interval.count('years')
        }
    },
    firstName(fullName){
        return fullName.indexOf(' ') != -1 ? fullName.substr(0, fullName.indexOf(' ')).trim() : fullName;
    },
    lastName(fullName){
        return fullName.indexOf(' ') != -1 ? fullName.substr(fullName.indexOf(' ')).trim() : null;
    },
    slugsPlansWordpressCloud: [
        'wp-basic-usd',
        'wp-essential-usd',
        'wp-ultra-usd',
        'wp-hyper-usd',
        'wp-elite-usd',

        'wp-basic-eur',
        'wp-essential-eur',
        'wp-ultra-eur',
        'wp-hyper-eur',
        'wp-elite-eur',

        'wp-basic-vip-brl',
        'wp-basic-brl',
        'wp-essential-brl',
        'wp-ultra-brl',
        'wp-hyper-brl',
        'wp-elite-brl'
    ],
    slugCheetahTemplate: 'premium-template',
    slugBuyDomain: 'buy-domain', //TODO Foi feito o slugBuyDomainRegistry. Pode remover apos subir para produção o novo.
    slugBuyStorage: 'buy-storage',
    slugBuyDomainExtraConnection: 'buy-domain-extra-connection',
    slugBuyDomainRegistry: 'domain',
    /**
     * 
     * @param SlugUrlAccess
     * @param User
     * @param UserFather
     * @returns {string} planOrFeeInactive | tempRedir | office
     */
    redirUserPlanTrialOrPromotional(SlugUrlAccess, User, UserFather){
        let planStyle = TempRedirPlan.verifyAccessUserPlan(SlugUrlAccess, User.currency_code);

        //Logica realizada pq os disparos dos e-mail foi feito errado. E so pode comprar novamente quem ja esta no plano promotional
        if(
            (planStyle == 'NICHE') ||
            (planStyle == 'PROMOTIONAL' && TempRedirPlan.isRedirUser() && !['promotional-premium-usd-9', 'promotional-premium-eur-9', 'promotional-premium-brl-19'].includes(User.current_plan['slug']))
        ){
            return 'planOrFeeInactive';
        }

        //Slugs que nao sao de vendas, apenas cadastro
        if(planStyle == 'PREMIUMTRIAL' && ['test-brl', 'test-usd', 'test-eur'].includes(SlugUrlAccess)){
            return 'tempRedir';
        }

        //Regrar para os planos Premium Trial
        if(['premium-trial-usd-69', 'premium-trial-eur-59', 'premium-trial-brl-149', 'advanced-plan-trial'].includes(SlugUrlAccess)){
            //Se o usuario esta no plano free pode comprar
            if(['free-brl', 'free-usd', 'free-eur', 'free-czk', 'free-thb', 'free-inr'].includes(User.current_plan['slug'])) return 'doNotRedirect';

            //Se o usuario ja esta no trial e nao tem o support-link ou pay-now na url. faz o redirecionamento para o premium
            if(planStyle == 'PREMIUMTRIAL' && ['premium-trial-usd-69', 'premium-trial-eur-59', 'premium-trial-brl-149', 'premium-trial-30-brl', 'premium-trial-30-usd', 'premium-trial-30-eur', 'advanced-plan-trial'].includes(User.current_plan['slug'])){
                return 'tempRedir';
            }

            //Qual outro plano que estiver, faz redir para o office
            return 'office';
        }
    },
    validateVat(vat){
        return (RegExp(/^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/))
            .test(vat)
    },
    filterToolsList(listTool){
        let namesToolRemove = [
            'WhatsApp',
            'Builderall Whatsapp Automation Tool',
            'BuilderZap',
            'BuilderallZap',
            'WhatsApp Launch Manager',
            'Gestionnaire de lancement WhatsApp',
        ];

        return listTool.filter(tool => !namesToolRemove.includes(tool))
    },
    convertNumber(number){
        let lang = navigator.language || 'en';

        return (new Intl.NumberFormat(lang).format(number))
    },
    getContactEmailBuilderall(){
        return this.getLangBrowser() == 'pt' ? 'contato@builderall.com' : 'contact@builderall.com'
    },
    convertGb(storage_kb){
        return (storage_kb / 1024 / 1024);
    },
    getPgm() 
    {
        if(sessionStorage.getItem('pgm')) return sessionStorage.getItem('pgm');

        return Math.random().toString(36).substr(2, 16) + (new Date()).getTime();
    },
    appendPgm() 
    {
        const url = new URL(window.location);

        if (!url.searchParams.has('pgm')) {
            let pgmHash = this.getPgm();

            url.searchParams.append('pgm', pgmHash);

            window.history.pushState(null, null, url.toString());

            sessionStorage.setItem('pgm', pgmHash);
        }
    },
    getNewNameCouponSuper(coupon, slug)
    {
        let data = {
            hasNewNameCoupon: false,
            newCoupon: ''
        }
        
        if(coupon.toUpperCase() == 'SUPER' && slug == 'core-account') {
            data.hasNewNameCoupon = true;
            data.newCoupon = 'SUPER';
        }

        if(coupon.toUpperCase() == 'SUPER' && ['essentials-plan', 'wp-essentials-plan'].includes(slug)) {
            data.hasNewNameCoupon = true;
            data.newCoupon = 'SUPER1';
        }

        if(coupon.toUpperCase() == 'SUPER' && ['advanced-plan', 'wp-advanced-plan'].includes(slug)) {
            data.hasNewNameCoupon = true;
            data.newCoupon = 'SUPER2';
        }

        if(coupon.toUpperCase() == 'SUPER' && ['premium-plus-plan', 'wp-premium-plus-plan'].includes(slug)) {
            data.hasNewNameCoupon = true;
            data.newCoupon = 'SUPER3';
        }

        return data;
    }
}